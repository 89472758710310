@import "~@blueprintjs/core/lib/scss/variables";

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.sidebar {
  background-color: #EBEFF5;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  width: 300px;
  max-width: 320px;
  min-width: 320px;
  padding-top: 5px;
  border-left: 1px solid $gray4;

  img.icon-small {
    max-height: 24px;
    margin-left: 6px;
  }
  p.sidebar-text {
    padding: 12px 24px;
  }

  h4.inform {
    color: $gray3;
    text-align: center;
  }

  .bp3-menu {
    background: transparent;

    .bp3-menu-item {
      font-size: 12px;
      border-bottom: 1px solid $light-gray1
    }
  }

  .bp3-tag {
    font-weight: bold;
  }

  .bp3-tab-list {
    margin: 0 8px;
  }

  .bp3-label {
    text-transform: uppercase;
  }

  .bp3-button-text {
    font-weight: bold;
  }

  h6.bp3-heading.edit-heading {
    padding: 12px 0 8px 8px;
    margin: 0;
    background-color: $light-gray3;
  }

  .bp3-form-group.bp3-inline label.bp3-label {
    width: 110px;
    margin: 0;
    line-height: 30px;
  }

  .bp3-form-group.bp3-inline .bp3-slider {
    width: 100px !important;
    min-width: 100px;
    margin-left: 20px;
  }

  .bp3-form-group.bp3-inline .bp3-form-content{
    width: 100%;


    .bp3-input-ghost {
      width: 140px;
    }
  }

  .bp3-form-group.bp3-inline.with-help {
    align-items: center !important;

    .bp3-numeric-input {
      input{
        width: 140px !important;
      }
    }

    .bp3-numeric-input.input-half {
      padding-top: 0;
      input {
        width: 65px !important;
        margin-right: 0;
      }
    }

    .bp3-numeric-input.input-quarter {
      padding-top: 0;
      input {
        width: 32px !important;
        margin-right: 0;
      }
    }

    .bp3-popover-wrapper {
      margin-top: 0;

      .form-help-label {
      }

      input {
        width: 140px !important;
      }
    }
  }

  .bp3-inline .bp3-control-group.bp3-numeric-input {
    // margin: 0 0 0 10px;
    padding: 0;

    .bp3-input-group {
      margin: 0;

      input.bp3-input {
        width: 150px;
        margin: 0;
      }
    }
  }

  .with-help {
    .bp3-input-group {
      // width: 80px !important;
    }
  }


  .edit-block-simple {
    .bp3-form-group.bp3-inline {
      margin: 0;
      label.bp3-label {
        width: 238px;
        font-size: 12px;
        font-weight: bold;
      }

      .bp3-form-content {
        width: 30px;
        margin: 0;
        padding: 0;
      }
    }
  }

  .sidebar-edit, .sidebar-data {
    .edit-block {
      margin: 0 10px;

      .bp3-form-content {
        width: 100%;
      }
    }
  }
}

.map-popup.mapboxgl-popup {
  padding: 0;

  .mapboxgl-popup-tip {
    display: none;
  }

  .mapboxgl-popup-content {
    padding: 0;
    box-shadow: 0 2px 5px  rgba(0, 0, 0, 0.25);
    height: 30px !important;
  }

  .popup-toolbar-buttongroup {
    background: #dddddd;
  }
}

.map-popup.toolbar-popup {
  width: 220px;
  height: 120px;
  padding: 0;
  margin: 0;

  .bp3-dialog-body {
    margin: 0;
  }
  // height: 30px !important;
}

.map-toolbar {
  position: absolute;
  z-index: 10;
  border: 1px solid #a3a3a3;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.95);

  img.toolbar-icon {

  }

  .toolbar-button.bp3-button {
    width: 30px;
    height: 29px;
    margin: 0;
  }

  .bp3-button {
    margin: 0;
    border-bottom: 1px solid #dddddd;
  }
}

.map-selector {
  position: fixed;
  z-index: 10;
  top: 10px;
  // left: calc((100% - (100% / 5)) / 2 - 230px);
  left: calc(50% - 150px) ;
  width: auto;

  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #a3a3a3;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.95);
  font-size: 10px;
  white-space: nowrap;

  .sidebar-item {
    padding: 4px 20px;
  }

  .bp3-form-group {
    margin: 0;
  }

  .bp3-button.bp3-minimal {
    font-size: 12px;
    margin: 0;
  }

  .bp3-form-group.bp3-inline label.bp3-label {
    width: 60px;
    line-height: 34px;
    margin: 0;
    font-size: 10px;

  }
}

.bp3-form-group.bp3-inline label.bp3-label {
  line-height: 34px;
  font-weight: 500;
  color: $dark-gray4;
}

.sidebar-inner-tabs {
  .bp3-tab-list {
    font-weight: bold;
    border-bottom: 1px solid $light-gray2;
  }
}

.sidebar-tabs {
  // padding: 8px 12px;

  .bp3-tab {
    font-size: 12px;
  }

  .bp3-tab-panel {
    margin-top: 0;
  }

  .bp3-tab-list {
    font-weight: bold;
    border-bottom: 1px solid $light-gray2;
  }
}

.edit-block {
  border-top: 1px solid $light-gray2;
  margin: 0;
  padding: 6px 0;
  font-size: 12px;
  list-style: none;
  button {
    font-size: 12px;
  }

  h4 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .bp3-label {
    min-width: 110px;
  }

  .bp3-form-group {
    margin: 6px 0 0 0;
  }

  .bp3-form-content {
    margin-left: 0;
    width: 140px;

    .bp3-popover-wrapper {
      input {
        width: 140px;
      }
    }
  }

  .edit-block.menu-block {
    padding: 0 !important;
  }

  .bp3-form-group.bp3-inline {
    margin: 0;

    .bp3-switch {
      margin-left: 10px;
      margin-top: 9px;
    }
  }

  textarea {
    width: 100%;
  }
}

.settings {
  .bp3-form-group.bp3-inline label.bp3-label {
    width: 120px;
  }

  .edit-block {
    margin-left: 10px;
    .bp3-label {
      width: 200px;
    }
  }
}

.editor-amenity {
  width: 36px;
}

.loading {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;

  .loading-container {
    width: auto;
  }
}

.marker {
  width: 24px;
  height: 24px;
}

.route-bar {
  left: 10px;
  bottom: 10px;
  height: 30px;

  .route-bar-content {
    line-height: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.changes-bar {
  left: 10px;
  top: 10px;
  height: 30px;
  padding-right: 10px;

  .changes-bar-content {
    line-height: 30px;
    padding-left: 10px;
    cursor: pointer;
  }
}

.draw-tools {
  right: 10px;
  top: 10px;
  height: 212px;
  width: 30px;
  padding-right: 10px;
}

.draw-tools.shorter {
  height: 182px;
}

.change-inspector {
  table.change-inspector-table {
    font-size: 12px;
    width: 100%;
  }
}

.form-help-container {
  padding: 10px;
  width: 250px;
}

.form-help-content {
  margin-bottom: 10px;
}

.form-help-label {
  cursor: help;
}

.form-help-requirement {
  color: $red1;
  font-weight: bold;
  margin-bottom: 10px;
}

.form-help-options {
  margin-top: 5px;
  padding: 5px 0 0 0;
  border-top: 1px solid $light-gray1;
}

.form-help-option-row {
  padding: 5px 0 0 0;
}

.form-help-option-title {
  font-weight: bold;
}

.layer-tabs {

  .bp3-tab-list {
    margin: 0;
  }

  .bp3-tab-panel {
    margin-bottom: 30px;
  }
}

.layer-collapse {
  .bp3-collapse-body {
    border: 2px solid  #1D7DBB;
  }
}

.metadata-collapse {
  .bp3-collapse-body {
    padding: 0 5px;
    border: 2px solid $light-gray3;
  }
}

.layer-search-field {
  margin: 5px;
  input.bp3-input {
    box-shadow: none;
    height: 40px;
  }
}

.small-buttons {
  border-bottom: 1px solid $light-gray1;
  button {
    font-size: 12px;
    text-transform: uppercase;
  }
}

.top-menu-item {
  background-color: $light-gray3;
}

.selection-no-margin button {
  margin: 0;
}

.date-input input {
  border: 0 !important;
  border-radius: 0 !important;
}

.bp3-menu-item {
  font-weight: bold;
}

.amenity-editor {
  .bp3-menu {
    background-color: transparent;
  }

  input.top {
    padding: 4px 6px;
    font-size: 16px;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $light-gray3;
    margin-left: 10px;
  }

  img.preview {
    width: 72px;
    height: 72px;
    margin-top: 20px;
  }

  .amenity-editor-selected {
    margin: 4px 0;

    .form {
      margin: 20px;
    }
  }

  .bp3-form-group.bp3-inline {
    .bp3-label {
      width: 100px;
      line-height: 34px;
    }
  }
}

.amenity-editor-amenity {
  height: 130px;
  width: 130px;
  border: 1px solid $light-gray3;
  text-align: center;
  margin: 5px 10px 10px 10px;
  padding: 0 5px 0 5px;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;

  img {
    width: 72px;
    height: 72px;
    margin-top: 10px;
  }

  &:hover {
    background-color: $light-gray2;
  }
}

.amenities-icons-browser {
  height: 460px;
  overflow-y: scroll;
}

.bp3-dialog-footer {
  padding-top: 10px;
  border-top: 1px solid $light-gray2;
}

.amenity-editor-mapping {
  h1 {
    margin-top: 10px;
    font-size: 24px;
    font-weight: normal;
  }
}

.menu-header h6.bp3-heading  {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.menu-section-header h6.bp3-heading {
  font-size: 12px;
  font-weight: bold;
  text-align: left;
}

.bp3-button-group .bp3-popover-wrapper.menu-button-tooltip {
  width: 40px;
  display: block;
  flex: 0;
}

.feature-media-container {
  padding: 2px;

  .feature-media {
    width: 92px;
    border: 1px solid transparent;
    &:hover {
      border: 1px solid $light-gray2;
      cursor: pointer;
    }

    img {
      width: 90px;
      min-width: 90px;
      max-width: 90px;
      min-height: 30px;
      max-height: 90px;
    }
  }
}

.image-viewer-container {
  width: 900px;
  text-align: center;
  img {
    max-width: 900px;
    max-height: 500px;
    margin: 0 auto;
  }
}

.hint {
  font-size: 10px;
  color: #888;
}

.list-icon {
  width: 20px;
  max-height: 20px;
}

.list-no-results {
  font-size: 12px;
  font-weight: bold;
  color: $dark-gray2;
  padding: 4px 6px;
}

.login-error {
  width: 100%;
  margin: 0 auto;
  font-weight: bold;
  text-align: center;
  color: #800;
}

.login-content {
  img.logo-top {
    margin: 20px auto;
    width: 100%;
  }
}

.obj-inputs {
  input {
    width: 120px;
  }
}

.ai-c {
  align-items: center;
}

.jc-c {
  align-items: center;
}

.m-h-6 {
  margin-left: 6px;
  margin-right: 6px;
}

.m-h-12 {
  margin-left: 12px;
  margin-right: 12px;
}

.working_hours {
  .bp3-collapse-body {
    .bp3-form-group.bp3-inline {
      label.bp3-label {
        width: 60px;
        max-width: 60px;
        min-width: 60px;
        padding-left: 6px;
      }
    }
  }
  .bp3-timepicker {
    .bp3-timepicker-input-row {
      width: 78px;
      .bp3-timepicker-input {
        width: 36px;
        padding-left: 6px;

      }
    }
  }
}

.direction-item {
  padding: 12px 12px 12px 12px;
  border-bottom: 1px solid $gray5;

  .title {
    flex: 3;
  }

  .distance {
    width: 72px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .instruction {
    margin-top: 12px;
  }
}

.CodeMirror {
  height: 100% !important;
}

button.very-small-btn.bp3-button {
  font-size: 10px;
  min-height: 10px;
  height: 16px;
  padding: 2px 4px;
}

.p-24 {
  padding: 24px;
}

.property-row {
  .bp3-label {
    width: 180px;
  }
}

.f-1 {
  flex: 1
}

.ai-s { align-items: start; }
.ai-c { align-items: center; }
.ai-e { align-items: end; }
.jc-s { justify-content: start; }
.jc-c { justify-content: center; }
.jc-e { justify-content: end; }
.ta-c { text-align: center; }

.bp3-menu-item.hidden {
  position: absolute !important;
  z-index: 1000 !important;
  bottom: 24px;
  right: 24px;
  width: 40px;
  background-color: red;
  cursor: initial !important;;
  opacity: 0;
  a {
    cursor: initial !important;
  }
}

.input-hollow {
  font-size: 6px;
  background: transparent;
  border: none;
  opacity: 0;
  width: 50px;
}

#mode-selector {
  position: absolute;
  bottom: 10px;
  right: 330px;
  width: 200px;
  display: flex;

  .bp3-button {
    font-weight: bold;
    text-align: center;
    font-size: 12px;
  }
}

.m-r-12 { margin-right: 12px; }

.btn-icon {
  height: 16px;
}
